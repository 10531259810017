export enum STATUS {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  DEFAULT = 'DEFAULT',
}

export const PHONE_APPROVAL_AUTHORITY_NUMBER_DEFAULT_VALUE = 'P9999RX'

export const REQUIRE_PHONE_AUTH_RESTRICTION_TYPES = [
  'Online authority required',
  'Written authority required',
]

export const EMERGENCY_SUPPLY_TOOLTIP =
  'An emergency supply, or owing prescriptions (sometimes called faxed prescriptions) are those which are given to patients before the actual prescription is received by the pharmacy.'
export const UNUSUAL_DOSE_TOOLTIP =
  'If a prescriber determines the maximum quantity or number of repeats should be increased for a patient, they must tick this checkbox and complete an Authority PBS Prescription by calling the DVA freecall hotline (1800 552 580) or Services Australia PBS Authority hotline (1800 888 333).'
export const REGULATION_49_TOOLTIP =
  'Ticking this checkbox will result in the patient being able to receive all their original and repeat supplies at one time.'
export const DO_NOT_SEND_TO_ACTIVE_SCRIPT_LIST_TOOLTIP =
  'Tick this checkbox to opt-out of sending this prescription to a patient’s Active Script List. You can still issue an electronic prescription by sending a token for the patient to use to have their medicines supplied.'
export const BRAND_SUBSTITUTION_NOT_PERMITTED_TOOLTIP =
  'Ticking this checkbox will prevent dispensers from substituting the medication on this prescription for another brand.'
export const BRAND_IS_REQUIRED_TOOLTIP =
  'Ticking this chekcbox will enable a brand name of this LMBC drug to be shown on the prescription, should the prescriber deem the identification of a brand to be clinically appropriate.'
export const CONTROLLED_SUBSTANCE_TOOLTIP = `
NSW - Authorisation Reference Number;
VIC/ACT/WA - Permit or Warrant Number;
QLD - Approval Number
`
export const SEND_TO_PHARMACY_TOOLTIP = `
Selecting this option delivers the prescription details to the patient's My Script List (MySL).

IMPORTANT: If the patient is not registered with MySL, DO NOT select this option as no electronic token will be generated.
`

export const PROVIDE_EXISTING_SCRIPT_NUMBER_TOOLTIP =
  'Click here to generate a script number for this prescription. Please remember to note down this script number for prescriptions with delayed authority. When you receive an authority number, simply click provide an existing script number and enter it there.'

export const DESKTOP_BREAKPOINT = '62em'
export const TABLET_BREAKPOINT = '48em'

export const REPEAT_INTERVAL_TOOLTIP =
  'Specifies the minimum interval between supplies in days. A minimum interval is required for certain medications.'

export const TOOLTIP_TEXT = {
  REFERRAL_CODE:
    'Sign up using a referral code and unlock an extra 15 days of free trial with RxPad.',
  HPI_I:
    'If unsure about your HPI-I, call the HI Service enquiry line on 1300 361 457, Monday to Friday, 8:30am- 5:00pm (local time) and request your HPI-I.',
  PBS_PRESCRIBER_NUMBER:
    "This is not your provider number. A prescriber number is a 6 or 7 digit number that uniquely identifies that you're eligible to prescribe medication under the PBS.",
  ENTITY_ID:
    'Before you can generate electronic prescriptions, each prescriber must register themselves on eRx. Each prescriber in a practice needs to register individually. If you work in more than one practice you will need to register multiple times.',
  OTHER_PHONE_NUMBER:
    'This phone number will appear at the top of all printed prescriptions and will be visible to pharmacists during dispensing.',
}

export const MONTH_LIST = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const DATE_OF_BIRTH = 'yyyy-MM-dd'

export const SSO_ORGANIZATION_ID_LIST = [
  process.env.REACT_APP_HDA_ORG_ID,
  process.env.REACT_APP_TELECARE_ORG_ID,
]
