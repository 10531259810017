import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ApiClient from '../../utils/api/ApiClient'
import { HIStatus, Patient, RecordStatus } from '../../types'

export interface HIServiceMessage {
  codeField: string
  severityField: number
  reasonField: string
  detailsField: string | null
}

export interface HIServiceMessages {
  highestSeverityField: number
  serviceMessageField: HIServiceMessage[]
}

export interface SearchPatientRequest {
  searchTerm: string
}
export type SearchPatientResponse = Patient[]

export type IhiGender = 'M' | 'F' | 'I' | 'N'
export interface PatientIhiSearchRequest {
  GivenName: string
  FamilyName: string
  DOB: string
  Gender: IhiGender
  MedicareIRN?: string
  MedicareCardNumber?: string
  IhiNumber?: string
  DvaFileNumber?: string
  HpiiNumber?: string
  HpioNumber?: string
}

export type PatientIhiSearchResponse =
  | PatientIhiSearchSuccessfulResponse
  | PatientIhiSearchErrorResponse

export interface PatientIhiSearchSuccessfulResponse {
  IHI: string
  HIStatus: HIStatus
  RecordStatus: RecordStatus
  ServiceMessages: HIServiceMessages | null
}

export interface PatientIhiSearchErrorResponse {
  Message: string
}

export interface GetPatientRequest {
  prescriberId: string
  patientId: string
}
export interface GetPatientResponse {
  patient: Patient
}

export const PatientClient = {
  // IHI Check
  checkIhi: async (request: PatientIhiSearchRequest) => {
    const query = new URLSearchParams({
      ...request,
    })

    const response = await ApiClient.get(`/hi-service/patient/search?${query.toString()}`)
    return response.data as PatientIhiSearchResponse
  },
}

export interface PatientState {
  selectedPatient: Patient
}
export const blankPatient = {
  id: '',
  given_names: '',
  family_name: '',
  gender: '',
  date_of_birth: '',
  medicare_no: '',
  medicare_irn: '',
  medicare_valid_to: '',
  dva_file_no: '',
  dva_card_colour: '',
  ihi_number: '',
  ihi_status: '',
  record_status: '',
  entitlement_no: '',
  pbs_entitlement_no: '',
  concession_pension_no: '',
  pbs_safety_net_entitlement_card_holder: false,
  pbs_concession_card_holder: false,
  ctg_flag: false,
  hospital_category: false,
  email: '',
  phone_number: '',
  address_1: '',
  address_2: '',
  suburb: '',
  state: '',
  postcode: 0,
  racfid: '',
  paper_only: false,
}
const initialState: PatientState = {
  selectedPatient: { ...blankPatient },
}

export const PatientDetailSlice = createSlice({
  name: 'patientDetail',
  initialState,
  reducers: {
    selectPatient: (state, { payload: patientDetails }: PayloadAction<Patient | undefined>) => {
      state.selectedPatient = patientDetails ?? { ...blankPatient }
    },
  },
})

export const { selectPatient } = PatientDetailSlice.actions

export default PatientDetailSlice.reducer
