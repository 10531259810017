import { FunctionComponent } from 'react'
import LoginPage from './AuthenticationPage/LoginPage'
import AuthenticationPage from './AuthenticationPage/AuthenticationPage'
import NewScriptPage from './NewScriptPage/NewScriptPage'
import PatientsPage from './PatientsPage/PatientsPage'
import PrescriptionsPage from './PrescriptionsPage/PrescriptionsPage'
import SettingsPage from './SettingsPage/SettingsPage'
import PrescriberProfileCreationPage from './PrescriberProfileCreationPage/PrescriberProfileCreationPage'
import GenericErrorPage from './ErrorPage/GenericErrorPage'
import LoadingPage from './LoadingPage'
import OrganizationCreationDeveloperPage from './OrganizationCreationDeveloperPage/OrganizationCreationDeveloperPage'
import ClinicCreationPage from './ClinicCreationPage/ClinicCreationPage'
import OrganizationLinkPage from './OrganizationCreationPage/OrganizationLinkPage'
import PricePlanPage from './Billing/PricePlanPage'
import { ReferralPage } from '../features/refer/ReferralPage'
import { ExperimentsPage } from '../features/experiments/ExperimentsPage'
import { SSOLandingPage } from '../features/sso/SSOLandingPage'

export interface AppRoute {
  path: string
  Component: FunctionComponent
  requiresAuth?: boolean
}

export const routes: Record<string, AppRoute> = {
  root: {
    path: '/',
    Component: LoadingPage,
    requiresAuth: true,
  },
  sso: {
    path: '/sso/:identityProviderName',
    Component: SSOLandingPage,
  },
  login: {
    path: '/login',
    Component: LoginPage,
  },
  authWithClientId: {
    path: '/auth/:clientId',
    Component: AuthenticationPage,
  },
  createPrescriberProfile: {
    path: '/create-prescriber-profile',
    Component: PrescriberProfileCreationPage,
    requiresAuth: true,
  },
  linkOrganization: {
    path: '/create-organization/link-to-clinic',
    Component: OrganizationLinkPage,
    requiresAuth: true,
  },
  createClinic: {
    path: '/create-organization/new-clinic',
    Component: ClinicCreationPage,
    requiresAuth: true,
  },
  pricePlan: {
    path: '/price-plans',
    Component: PricePlanPage,
    requiresAuth: true,
  },
  newScript: {
    path: '/new-script',
    Component: NewScriptPage,
    requiresAuth: true,
  },
  patients: {
    path: '/patients',
    Component: PatientsPage,
    requiresAuth: true,
  },
  patientScripts: {
    path: '/patients/:patientId/scripts',
    Component: PrescriptionsPage,
    requiresAuth: true,
  },
  prescriptions: {
    path: '/prescriptions',
    Component: PrescriptionsPage,
    requiresAuth: true,
  },
  refer: {
    path: '/refer',
    Component: ReferralPage,
    requiresAuth: true,
  },
  settings: {
    path: '/settings',
    Component: SettingsPage,
    requiresAuth: true,
  },
  experiments: {
    path: '/experiments',
    Component: ExperimentsPage,
    requiresAuth: true,
  },
  createOrganizationDeveloper: {
    path: '/admin-tools/crt-org',
    Component: OrganizationCreationDeveloperPage,
  },
  error: {
    path: '/error',
    Component: GenericErrorPage,
  },
}
