import { QuestionIcon, SettingsIcon, TimeIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  Wrap,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { format, parseISO } from 'date-fns'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'
import { RootState } from '../../app/store'
import SearchInput from '../../components/form/SearchInput'
import Pagination from '../../components/RCPagination'
import ClickableTooltip from '../../components/shared-components/ClickableTooltip'
import { useGetPatientsByPrescriberIdQuery } from '../../services/patients'
import { Patient } from '../../types'
import { printIhiNumber } from '../../utils/ihi-number-printer/ihi-number-printer'
import { routes } from '../routes'
import { DVA_CARD_COLOUR } from './constants'
import IHIHistoryModal from './IHIHistoryModal'

const StyledWrapper = styled(Wrap)`
  border: 1px solid #555555;
  border-radius: 16px;

  ul {
    height: 100%;
  }
`

const PatientTable: React.FC<{
  setSelectedPatient: (patient: Patient) => void
  isDeactivatedPrescriber: boolean
  isAbleToManagePatient: boolean
}> = ({ setSelectedPatient, isDeactivatedPrescriber, isAbleToManagePatient }) => {
  const prescriberId = useSelector(({ prescriber }: RootState) => prescriber?.prescriber?.id)
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const { data, error, isLoading } = useGetPatientsByPrescriberIdQuery({
    prescriberId,
    page,
    limit: 10,
    searchTerm,
  })

  const tryOrElse = <T, R>(code: () => T, orElse: (err: unknown) => R) => {
    try {
      return code()
    } catch (err) {
      return orElse(err)
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  if (error || !data) {
    return (
      <Center bg="tomato" h="100px" color="white">
        Something went wrong!
      </Center>
    )
  }

  return (
    <VStack alignItems="stretch" pr={6} minHeight={0}>
      <SearchInput
        w={['80%', '20%']}
        alignSelf="flex-start"
        placeholder="by given or family name"
        onSearch={(value) => {
          const formattedValue = value.replace(/\s/g, '').trim()
          setPage(1)
          setSearchTerm(formattedValue)
        }}
      />
      <StyledWrapper border="1px solid #555555" borderRadius="10px">
        <TableContainer overflowY="scroll" height="100%" backgroundColor="white" width="100%">
          <Table variant="striped" size={['md', 'lg']}>
            <Thead>
              <Tr>
                <Th>
                  <ClickableTooltip
                    label="Edit patient or view the prescription history for a particular patient"
                    placement="top"
                  >
                    <HStack>
                      <span>Actions</span>
                      <QuestionIcon />
                    </HStack>
                  </ClickableTooltip>
                </Th>
                <Th>Name</Th>
                <Th>Gender</Th>
                <Th>Date of Birth</Th>
                <Th>Medicare Number</Th>
                <Th>DVA File Number</Th>
                <Th>DVA card Colour</Th>
                <Th>IHI Number</Th>
                <Th>IHI Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.patients.map((patient) => (
                <Tr key={`${patient.id}`} height="50px">
                  <Td textAlign={'center'}>
                    <Menu>
                      <MenuButton as={Button} colorScheme="blue" variant="link" width={'100%'}>
                        <SettingsIcon />
                      </MenuButton>
                      <MenuList>
                        <Tooltip label="Edit the patient details." placement="right">
                          <MenuItem
                            onClick={() => setSelectedPatient(patient)}
                            isDisabled={isDeactivatedPrescriber || !isAbleToManagePatient}
                          >
                            Edit Patient
                          </MenuItem>
                        </Tooltip>
                        <Tooltip
                          label="View the prescription history for a particular patient."
                          placement="right"
                        >
                          <MenuItem>
                            <Link
                              to={generatePath(routes.patientScripts.path, {
                                patientId: patient.id,
                              })}
                            >
                              View Prescriptions
                            </Link>
                          </MenuItem>
                        </Tooltip>
                      </MenuList>
                    </Menu>
                  </Td>
                  <Td>{`${patient.given_names} ${patient.family_name}`}</Td>
                  <Td>{patient.gender}</Td>
                  <Td>
                    {tryOrElse(
                      () => format(parseISO(patient.date_of_birth), 'dd/MM/yyyy'),
                      () => 'Invalid DoB'
                    )}
                  </Td>
                  <Td>
                    {patient.medicare_no &&
                      patient.medicare_irn &&
                      `${patient.medicare_no}/${patient.medicare_irn}`}
                  </Td>
                  <Td>{patient.dva_file_no}</Td>
                  <Td>{DVA_CARD_COLOUR[patient.dva_card_colour ?? ''] ?? ''}</Td>
                  <Td>
                    {printIhiNumber(patient.ihi_number)}
                    <IHIHistoryModal ihiRecords={patient.ihi_records ?? []}>
                      {({ openModal }) => (
                        <Button
                          hidden={patient.ihi_records && patient.ihi_records.length < 1}
                          colorScheme="blue"
                          variant="link"
                          onClick={() => openModal()}
                        >
                          <Box width="16px">
                            <TimeIcon fill="currentcolor" />
                          </Box>
                        </Button>
                      )}
                    </IHIHistoryModal>
                  </Td>
                  <Td>
                    {patient.ihi_status &&
                      patient.record_status &&
                      `${patient.ihi_status}(${patient.record_status})`}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </StyledWrapper>
      <HStack mt="12" alignSelf="flex-start">
        <Pagination
          showLessItems
          current={page}
          total={data.meta.totalItems}
          pageSize={10}
          onChange={(currentPage) => setPage(currentPage)}
        />
      </HStack>
    </VStack>
  )
}
export default PatientTable
