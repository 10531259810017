import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import React, { FunctionComponent, useEffect, useState } from 'react'
import AuthorityDetailsPanel from './AuthorityDetailsPanel'
import ProductDetailsPanel from './ProductDetailsPanel'
import ProductDetailsCustom from './ProductDetailsPanelCustom'
import PrescribedItemList from './PrescribedItemList'
import PatientDetailsPanel from './PatientDetailsPanel'
import PrescriberDetailsPanel from './PrescriberDetailsPanel'
import SendScriptPanel from './SendScriptPanel/SendScriptPanel'
import { ScrollableContainer } from '../../components/shared-components/ScrollableContainer'
import ResetScriptModal from './ResetScriptModal'
import { PrescribedItem, Prescriber } from '../../types'
import { useAppDispatch, useAppSelector } from '../../app/typedReduxHooks'
import {
  detailsToPrescribedItem,
  selectedPrescribedItemSelector,
} from './ScriptForm/PrescribedItem'
import {
  addPrescribedItem,
  selectPrescribedItem,
  setAddPrescribedItemError,
  updatePrescribedItem,
} from './ScriptForm/PrescribedItem/PrescribedItemSlice'
import {
  ADD_PRESCRIBED_ITEM_FAILED_ERROR,
  initFormError,
  UPDATE_PRESCRIBED_ITEM_FAILED_ERROR,
} from './constants'
import {
  PbsConcise,
  PbsRestriction,
  resetProductResults,
  selectProduct,
  setSearchTerm,
} from './ScriptForm/ProductSearchContextSlice'
import { isAbleToAddNewPBS, isAbleToAddNewPrescribedItem } from './utilities'
import { transformPbsData } from './ScriptForm/PrescribedItem/pbsDataTransformer'
import UnauthorizedPage from '../../components/UnauthorizedPage'
import MimsIntegrated from '../../assets/mims-integrated.png'
import { preCheckProduct } from './ScriptForm/PrescribedItem/utils/preCheckProduct'
import { PrescribedItemErrors } from '../../types/prescription'
import { RootState } from '../../app/store'
import { useFeatureToggle } from '../../common/utils/feature-toggle/useFeatureToggle'
import ResponsiveStack from '../../components/shared-components/ResponsiveStack'
import { SubscriptionNotificationModal } from '../Billing/SubscriptionNotificationModal'

const NewScriptPage: FunctionComponent<unknown> = () => {
  const dispatch = useAppDispatch()
  const { isFeatureEnabled: isOrgRegistrationAndPaymentFeatureOn } = useFeatureToggle(
    'ORG_REGISTRATION_AND_PAYMENT'
  )
  const permissions = useAppSelector((state) => state.authentication.permissions)
  const prescriber: Prescriber | null = useAppSelector(
    ({ prescriber }: RootState) => prescriber?.prescriber
  )
  const { prescribedItemList, selectedPrescribedItemIndex } = useAppSelector(
    (state) => state.newScript
  )
  const { error: subscriptionError } = useAppSelector(
    (state: RootState) => state.billing?.subscription
  )
  const organizationSettings = useAppSelector((state) => state.organization?.data?.settings)
  const isAbleToSelectS8Medicine = organizationSettings?.enableS8Medicine
  const requireReasonForPrescription = organizationSettings?.requireReasonForPrescription
  const enableWebhooks = organizationSettings?.enableWebhooks
  const [isSubscriptionInvalid, setIsSubscriptionInvalid] = useState(false)

  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [formErrors, setFormErrors] = useState<PrescribedItemErrors>(initFormError)
  const selectedPrescribedItem = useAppSelector(selectedPrescribedItemSelector)
  const { selectedProduct, productSearchTerm } = useAppSelector((state) => state.product)
  const { isPaperPrescription } = useAppSelector((state) => state.newScript)
  const toast = useToast()

  const details =
    productSearchTerm.length >= 3 ? selectedProduct : selectedPrescribedItem?.productDetails

  useEffect(() => {
    if (selectedPrescribedItem) {
      const { quantity } = selectedPrescribedItem
      setDisabledSubmit(!quantity)
      return
    }
    setDisabledSubmit(true)
  }, [selectedPrescribedItem])

  // Handle Drawer
  const { isOpen, onClose, onToggle } = useDisclosure()

  const isFormValid = () => {
    if (!selectedPrescribedItem) return false
    const { patientInstructions, reasonForPrescribing } = selectedPrescribedItem
    let isValid = true
    let errors = {
      ...formErrors,
    }
    if (!patientInstructions) {
      errors = {
        ...errors,
        patientInstructions: {
          ...errors.patientInstructions,
          showError: !patientInstructions,
        },
      }
      isValid = false
    }
    if (requireReasonForPrescription && !reasonForPrescribing) {
      errors = {
        ...errors,
        reasonForPrescribing: {
          ...errors.reasonForPrescribing,
          showError: !reasonForPrescribing,
        },
      }
      isValid = false
    }
    setFormErrors({ ...formErrors, ...errors })
    return isValid
  }

  const handleSelect = (pbsData: PbsConcise, restriction?: PbsRestriction) => {
    if (!details) return

    const overrides = transformPbsData(pbsData, restriction)

    dispatch(setAddPrescribedItemError(undefined))

    // Handle Selected Case
    if (selectedPrescribedItem && selectedPrescribedItemIndex !== undefined) {
      const pbsArray = selectedPrescribedItem?.productDetails?.pbs || []
      const selectedPBS = pbsArray.find(({ pbsCode }) => pbsCode === overrides.pbsCode)
      if (!selectedPBS) {
        return
      }

      const isAbleToUpdatePBS = isAbleToAddNewPBS(selectedPBS, prescribedItemList)

      if (isAbleToUpdatePBS) {
        onClose()
        return dispatch(
          updatePrescribedItem({
            index: selectedPrescribedItemIndex,
            updatedItem: {
              ...selectedPrescribedItem,
              ...overrides,
            },
          })
        )
      }

      return dispatch(setAddPrescribedItemError(UPDATE_PRESCRIBED_ITEM_FAILED_ERROR))
    }
    // Handle Add Case
    const item = detailsToPrescribedItem(details, overrides)
    const { passed, message } = preCheckProduct(item.productDetails)
    if (message) {
      toast({
        title: passed ? 'Warning' : 'Error',
        description: message,
        status: passed ? 'warning' : 'error',
        duration: null,
        isClosable: true,
        position: 'top',
      })
    }
    if (!passed) {
      return
    }

    if (isAbleToAddNewPrescribedItem(item, prescribedItemList, isPaperPrescription)) {
      dispatch(setSearchTerm(''))
      dispatch(selectProduct(undefined))
      dispatch(resetProductResults())
      dispatch(setAddPrescribedItemError(''))

      return dispatch(addPrescribedItem(item))
    }

    return dispatch(setAddPrescribedItemError(ADD_PRESCRIBED_ITEM_FAILED_ERROR))
  }

  useEffect(() => {
    setIsSubscriptionInvalid(isOrgRegistrationAndPaymentFeatureOn && !!subscriptionError)
  }, [isOrgRegistrationAndPaymentFeatureOn, subscriptionError])

  return (
    <Flex height="100%" flexDirection="column" width="100%">
      {permissions?.includes('prescribing') ? (
        <ScrollableContainer flexGrow="1" flexBasis="0" id={'new-script'}>
          {isOrgRegistrationAndPaymentFeatureOn && <SubscriptionNotificationModal />}
          <VStack maxWidth="1200px" m="auto">
            <VStack p="48px 16px 16px" width="100%" alignItems="stretch" spacing="8">
              <ResponsiveStack
                gap={[0.1, 3]}
                justifyContent="space-between"
                alignItems={['center', 'stretch']}
              >
                <Text as="h1" fontSize="3xl" fontWeight="bold">
                  New Prescription
                </Text>
                <ResetScriptModal>
                  {({ openModal }) => (
                    <Button
                      onClick={openModal}
                      variant="outline"
                      colorScheme="orange"
                      alignSelf={['flex-end', 'stretch']}
                    >
                      Reset Script
                    </Button>
                  )}
                </ResetScriptModal>
              </ResponsiveStack>
              <PrescriberDetailsPanel />
              <PatientDetailsPanel />
              <PrescribedItemList
                prescribedItems={prescribedItemList}
                selectedPrescribedItemIndex={selectedPrescribedItemIndex}
                toggleDrawer={onToggle}
                onSelect={(item: PrescribedItem, index: number | undefined) => {
                  dispatch(selectPrescribedItem(index || 0))
                  if (item) onToggle()
                }}
                onAdd={(item: PrescribedItem) => {
                  dispatch(addPrescribedItem(item))
                  if (item) onToggle()
                }}
                onUpdate={(item: PrescribedItem, index: number) => {
                  dispatch(
                    updatePrescribedItem({
                      updatedItem: item,
                      index,
                    })
                  )
                }}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
                isAbleToSelectS8Medicine={!!isAbleToSelectS8Medicine}
              />
              <SendScriptPanel
                disabledSubmit={disabledSubmit || isSubscriptionInvalid}
                isFormValid={isFormValid}
                isActive={!!prescriber?.active}
                enableWebhooks={!!enableWebhooks}
              />
            </VStack>
          </VStack>

          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size="md"
            blockScrollOnMount={false}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>
                <HStack justifyContent="start" alignItems="start">
                  <Text>Product Details</Text>
                  <Image src={MimsIntegrated} alt="Mims Integrated" width="100px" />
                </HStack>
              </DrawerHeader>
              <DrawerBody>
                <VStack align="stretch" gap="16px">
                  {details ? (
                    <>
                      <ProductDetailsPanel details={details} />
                      <AuthorityDetailsPanel details={details} handleSelect={handleSelect} />
                    </>
                  ) : (
                    <ProductDetailsCustom details={selectedPrescribedItem} />
                  )}
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </ScrollableContainer>
      ) : (
        <UnauthorizedPage />
      )}
    </Flex>
  )
}

export default NewScriptPage
