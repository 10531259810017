import { PrescribedItemErrors } from '../../types/prescription'
import { AustralianState } from '../../types'

export const ADD_PRESCRIBED_ITEM_FAILED_ERROR =
  'Only 1 PBS item is allowed per script for e-prescribing (paperless). Up to 3 PBS items are allowed per script for paper prescriptions, except for Authority PBS items (only 1 permissible per script).'
export const UPDATE_PRESCRIBED_ITEM_FAILED_ERROR =
  'The new PBS you select violate the rule: only 3 PBS items are allowed per script, except for Authority PBS items (only 1 per script).'

export const initFormError: PrescribedItemErrors = {
  patientInstructions: {
    showError: false,
    message: 'Please enter directions',
  },
  reasonForPrescribing: {
    showError: false,
    message: 'Please enter reason for prescribing',
  },
}

export const RTPM_CHECK_REQUIRED_STATE = [
  AustralianState.WesternAustralia,
  AustralianState.Victoria,
  AustralianState.SouthAustralia,
  AustralianState.NorthernTerritory,
  AustralianState.Queensland,
  AustralianState.Tasmania,
]

export const RTPM_SYSTEM_MAPPING = {
  [AustralianState.AustralianCapitalTerritory]: 'Canberra Script',
  [AustralianState.NewSouthWales]: 'SafeScript NSW',
  [AustralianState.NorthernTerritory]: 'NTScript',
  [AustralianState.Queensland]: 'QScript',
  [AustralianState.WesternAustralia]: 'ScriptCheckWA',
  [AustralianState.Tasmania]: 'TasScript',
  [AustralianState.Victoria]: 'SafeScript',
  [AustralianState.SouthAustralia]: 'ScriptCheck SA',
}
