import { Button, Heading, HStack, Input, Text, VStack } from '@chakra-ui/react'
import debounce from 'lodash.debounce'
import { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from '../../app/typedReduxHooks'
import { RootState } from '../../app/store'
import Card from '../../common/components/Card'
import { patientsApi, useLazyGetPatientsByPrescriberIdQuery } from '../../services/patients'
import NewPatientModal from '../PatientsPage/NewPatientModal'
import { selectPatient } from '../../features/patient/PatientContextSlice'
import { setDeliveryState } from './ScriptForm/PrescribedItem/PrescribedItemSlice'
import { renderValidatedResult } from './jsxUtilities'
import { validateMedicareNumber } from '../../utils/validation-helpers/MedicareValidate'
import { validateIhi } from '../../utils/validation-helpers/IhiValidate'
import {
  displayIfPresent,
  validateDate,
  validateExists,
} from '../../utils/validation-helpers/Common'
import { Prescriber } from '../../types'
import { useLazyGetPatientByExternalIdQuery } from '../../services/patientsBFF'

const IhiGenderToLabel = {
  M: 'Male',
  F: 'Female',
  I: 'Intersex or Indeterminate',
  N: 'Not State',
}
type IhiGender = keyof typeof IhiGenderToLabel

const PatientDetailsPanel: FunctionComponent<unknown> = () => {
  const [patientSearchQuery, setPatientSearchQuery] = useState('')
  const [patientFetchMsg, setPatientFetchMsg] = useState('Patient data being acquired...')
  const prescriber: Prescriber | null = useAppSelector(
    ({ prescriber }: RootState) => prescriber?.prescriber
  )
  const organizationSettings = useAppSelector((state) => state.organization?.data?.settings)
  const isAbleToManagePatient = organizationSettings?.enableManagePatient
  const prescriberId = prescriber?.id
  const isDeactivatedPrescriber = !prescriber?.active
  const selectedPatient = useSelector(
    ({ patientDetail }: RootState) => patientDetail.selectedPatient
  )
  const dispatch = useAppDispatch()
  const landingPageFeedDataString = localStorage.getItem('landingPageParams')
  const landingPageFeedData = landingPageFeedDataString
    ? JSON.parse(landingPageFeedDataString)
    : null
  const ePrescriptionDataFeedId = landingPageFeedData?.eprescriptiondatafeedid
  const [syncPatient, syncPatientResult] = useLazyGetPatientByExternalIdQuery()
  const [searchPatients, searchResult] = useLazyGetPatientsByPrescriberIdQuery()
  const patientResults = searchResult?.data?.patients
  const externalPatient = syncPatientResult?.data

  useEffect(() => {
    setTimeout(() => setPatientFetchMsg('Failed to get patient data, please try again.'), 5000)
    if (ePrescriptionDataFeedId) {
      syncPatient({ ePrescriptionDataFeedId })
    }
  }, [ePrescriptionDataFeedId, syncPatient, dispatch, selectedPatient])

  useEffect(() => {
    dispatch(selectPatient(syncPatientResult.data))
  }, [externalPatient, dispatch, selectPatient])
  useEffect(() => {
    dispatch(
      setDeliveryState({
        recipientEmail: selectedPatient?.email,
        recipientMobile: selectedPatient?.phone_number,
      })
    )
  }, [dispatch, selectedPatient])

  const fetchSearchResult = () => {
    if (patientSearchQuery.length >= 2) {
      searchPatients({ prescriberId, searchTerm: patientSearchQuery })
    }
  }

  const debouncePatientsFetch = debounce(() => fetchSearchResult(), 1000)

  useEffect(() => {
    debouncePatientsFetch()
    return () => debouncePatientsFetch.cancel()
  }, [patientSearchQuery])

  return (
    <VStack alignItems="stretch">
      <Heading as="h4" size="md">
        Patient
      </Heading>
      <Card p="3">
        {selectedPatient.id ? (
          <VStack flexGrow="1" flexBasis="0" alignItems="stretch">
            <HStack>
              <Text fontWeight="bold">IHI:</Text>
              {renderValidatedResult(
                validateIhi(selectedPatient.ihi_number, selectedPatient.ihi_status)
              )}
            </HStack>
            <HStack>
              <Text fontWeight="bold">Given Name:</Text>
              <Text>{selectedPatient.given_names}</Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold">Family Name:</Text>
              <Text>{selectedPatient.family_name}</Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold">Gender:</Text>
              <Text>{IhiGenderToLabel[selectedPatient.gender as IhiGender]}</Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold">Date of Birth:</Text>
              {renderValidatedResult(
                validateDate(
                  selectedPatient.date_of_birth,
                  'Date of Birth',
                  "Please update this patient's Date of birth."
                )
              )}
            </HStack>
            <HStack spacing={4}>
              <HStack>
                <Text fontWeight="bold">Medicare Number:</Text>
                {renderValidatedResult(validateMedicareNumber(selectedPatient.medicare_no))}
              </HStack>
              <HStack>
                <Text fontWeight="bold">IRN:</Text>
                {renderValidatedResult(
                  validateExists(
                    selectedPatient.medicare_irn,
                    'Medicare IRN',
                    "Please update this patient's Medicare Individual Reference Number (IRN)."
                  )
                )}
              </HStack>
            </HStack>
            <HStack>
              <Text fontWeight="bold">Address:</Text>
              <VStack align="left" spacing="1px">
                <HStack>
                  {renderValidatedResult(
                    validateExists(
                      selectedPatient.address_1,
                      'Patient Address Line 1',
                      'Please update patient address.'
                    )
                  )}
                </HStack>
                <HStack>
                  {renderValidatedResult(
                    displayIfPresent(
                      selectedPatient.address_2,
                      'Patient Address Line 2',
                      'Please update patient address.'
                    )
                  )}
                </HStack>
                <HStack>
                  {renderValidatedResult(
                    validateExists(
                      selectedPatient.suburb,
                      'Patient Suburb',
                      'Please update patient address.'
                    )
                  )}
                  {renderValidatedResult(
                    validateExists(
                      selectedPatient.postcode,
                      'Patient Postcode',
                      'Please update patient address.'
                    )
                  )}
                  {renderValidatedResult(
                    validateExists(
                      selectedPatient.state,
                      'Patient State',
                      'Please update patient address.'
                    )
                  )}
                </HStack>
              </VStack>
            </HStack>
            {isAbleToManagePatient && (
              <Button
                onClick={() => {
                  dispatch(selectPatient(undefined))
                  setPatientSearchQuery('')
                }}
              >
                Select another patient
              </Button>
            )}
          </VStack>
        ) : isAbleToManagePatient ? (
          <VStack alignItems="stretch">
            <Text fontWeight="bold" flexGrow="1">
              Select a patient
            </Text>
            <Input
              maxWidth="100%"
              bgColor="white"
              placeholder="Search Patient"
              disabled={isDeactivatedPrescriber}
              value={patientSearchQuery}
              onChange={(e) => setPatientSearchQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  fetchSearchResult()
                }
              }}
            ></Input>

            {patientSearchQuery && patientResults?.length && (
              <VStack
                bgColor="white"
                boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                borderRadius="8px"
                border="1px solid gray.600"
                zIndex="1"
              >
                {patientResults?.map((patient) => (
                  <HStack
                    className="patient_search_result"
                    key={patient.id}
                    cursor="pointer"
                    padding="12px 16px"
                    borderRadius="8px"
                    width="100%"
                    _hover={{
                      backgroundColor: 'gray.100',
                    }}
                    onClick={() => {
                      dispatch(selectPatient(patient))
                      dispatch(patientsApi.util.resetApiState())
                    }}
                  >
                    <Text fontWeight="600" flexGrow="1">
                      {patient.given_names} {patient.family_name}
                    </Text>
                    <Text>
                      {patient.medicare_no} | {patient.medicare_irn}
                    </Text>
                  </HStack>
                ))}
              </VStack>
            )}
            <NewPatientModal onAdd={(patient) => dispatch(selectPatient(patient))}>
              {({ openModal }) => (
                <Button
                  colorScheme="blue"
                  onClick={() => openModal()}
                  disabled={isDeactivatedPrescriber}
                >
                  New Patient
                </Button>
              )}
            </NewPatientModal>
          </VStack>
        ) : (
          <Text>{patientFetchMsg}</Text>
        )}
      </Card>
    </VStack>
  )
}

export default PatientDetailsPanel
