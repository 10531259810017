import { FC, useEffect, useState } from 'react'
import { HStack, Box, useMediaQuery } from '@chakra-ui/react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { AppRoute, routes } from '../pages/routes'
import { useAppDispatch, useAppSelector } from './typedReduxHooks'
import { DESKTOP_BREAKPOINT } from '../constants'
import HeaderBar from '../components/shared-components/HeaderBar'
import Nav from '../components/shared-components/SideNav'
import IdleTimer from '../utils/idle-timer'
import UserAgreementPage from '../pages/UserAgreementPage'
import { appDidFinishLaunching } from './appSlice'
import { useLogout } from '../hooks/useLogout'

import './styles/App.css'

const App = () => {
  const dispatch = useAppDispatch()
  const idleTimeout = useAppSelector((state) => state.organization?.data?.settings?.idleTimeout)
  const logout = useLogout()

  useEffect(() => {
    const timer = new IdleTimer({
      onTimeout: () => {
        // do not need to to log out when user never logs in
        if (
          !localStorage.getItem('access_token') &&
          !localStorage.getItem('refresh_token') &&
          !localStorage.getItem('client_id')
        ) {
          return
        }
        logout()
      },
      timeoutInMs: (idleTimeout || 10 * 60) * 1000, // default value: 10 mins
    })
    if (!localStorage.getItem('access_token')) {
      return
    }
    dispatch(appDidFinishLaunching())

    return () => timer.cleanUpTracker()
  }, [idleTimeout])

  return (
    <Routes>
      {Object.values(routes).map(({ path, Component, requiresAuth }) => (
        <Route
          path={path}
          element={
            requiresAuth ? <AuthComponent path={path} Component={Component} /> : <Component />
          }
          key={path}
        />
      ))}
    </Routes>
  )
}
const routesWithoutNav = [
  routes.createPrescriberProfile.path,
  routes.createClinic.path,
  routes.linkOrganization.path,
  routes.pricePlan.path,
  routes.error.path,
  routes.root.path,
]

const routesWithoutPrescriber = [
  routes.createPrescriberProfile.path,
  routes.createClinic.path,
  routes.linkOrganization.path,
]

const routesShouldGotToNewScript = [
  routes.createPrescriberProfile.path,
  routes.login.path,
  routes.root.path,
]

const AuthComponent: FC<AppRoute> = ({ Component, path }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const logout = useLogout()

  const [isSmallScreenNavOpen, setIsSmallScreenNavOpen] = useState(false)
  const [isDesktop] = useMediaQuery(`(min-width: ${DESKTOP_BREAKPOINT})`)
  const authentication = useAppSelector((state) => state.authentication)
  const [isPrescriberProfileExist, setIsPrescriberProfileExist] = useState(false)

  useEffect(() => {
    if (!authentication?.username) {
      navigate(routes.login.path)
      return
    }
  }, [navigate, authentication])
  useEffect(() => {
    if (authentication?.hasError) {
      navigate(routes.error.path)
      setIsPrescriberProfileExist(false)
      return
    }
  }, [authentication, navigate])
  useEffect(() => {
    if (!authentication?.username) return
    if (authentication?.hasError) return
    if (
      authentication?.permissions &&
      !authentication?.permissions?.includes('prescribing') &&
      !authentication?.hasError
    ) {
      setIsPrescriberProfileExist(false)
      !routesWithoutPrescriber.includes(location.pathname) &&
        navigate(routes.createPrescriberProfile.path)
    } else if (authentication?.permissions?.includes('prescribing')) {
      setIsPrescriberProfileExist(true)
    }
  }, [location, navigate, authentication])

  useEffect(() => {
    if (!authentication?.username) return
    if (authentication?.hasError) return
    const checkAuthentication = async () => {
      if (
        !localStorage.getItem('access_token') ||
        !localStorage.getItem('refresh_token') ||
        !localStorage.getItem('client_id')
      ) {
        await logout()
        return
      }
      if (isPrescriberProfileExist && routesShouldGotToNewScript.includes(location.pathname)) {
        navigate(routes.newScript.path)
        return
      }
    }
    checkAuthentication()
  }, [authentication, dispatch, isPrescriberProfileExist, location, logout, navigate])

  if (!authentication?.username) {
    return null
  }

  if (routesWithoutNav.includes(path)) {
    return <Component />
  }
  return (
    <>
      <Box>
        {!isDesktop && (
          <HeaderBar onToggleNav={() => setIsSmallScreenNavOpen(!isSmallScreenNavOpen)} />
        )}
        <HStack
          height={isDesktop ? '100vh' : 'calc(100vh - 67px)'}
          alignItems="stretch"
          bgColor="blackAlpha.100"
          width="100%"
        >
          <Nav
            isSmallScreenNavOpen={isSmallScreenNavOpen}
            onCloseSmallScreenNav={() => setIsSmallScreenNavOpen(false)}
          />
          <Component />
        </HStack>
      </Box>
      {isPrescriberProfileExist && <UserAgreementPage />}
    </>
  )
}

export default App
