import { ATSI_STATUS } from '../../types/patient'

export const DVA_CARD_COLOUR: { [key: string]: string } = {
  G: 'Gold',
  W: 'White',
  O: 'Orange',
}

export const ATSI_STATUS_OPTIONS: { [key in ATSI_STATUS]: string } = {
  ABORIGINAL: 'Aboriginal',
  TORRES_STRAIT_ISLANDER: 'Torres Strait Islander',
  BOTH: 'Aboriginal and Torres Strait Islander',
  NEITHER: 'Neither Aboriginal nor Torres Strait Islander',
  NOT_STATED: 'Not Stated',
  UNKNOWN: 'Unknown',
}
