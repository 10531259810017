import ApiClient from '../../utils/api/ApiClient'

export interface PublishHiCheckErrorPayloadDto {
  patientId: string
  isIHIValid: boolean
  isHPIIValid: boolean
  isHPIOValid: boolean
}

export const TenantClient = {
  publishHICheckErrors: async (data: PublishHiCheckErrorPayloadDto) => {
    await ApiClient.post('/bff/tenant/hi-check/errors', data)
  },
}
