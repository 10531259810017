import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { FunctionComponent, useState } from 'react'

interface ConfirmationModalProps {
  children: FunctionComponent<{
    openModal: () => void
  }>
  title: string
  body: string
  onConfirm: () => void
  onClose?: () => void
}

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  title,
  body,
  onConfirm,
  onClose,
  ...props
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  return (
    <>
      <props.children openModal={() => setModalIsOpen(true)}></props.children>

      <Modal
        isOpen={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false)
        }}
      >
        <ModalOverlay />
        <ModalContent width="100%" maxWidth="600px">
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack>
              <Text>{body}</Text>
            </HStack>
          </ModalBody>

          <ModalFooter justifyContent="space-between">
            <Button
              mr={3}
              onClick={() => {
                onClose && onClose()
                setModalIsOpen(false)
              }}
            >
              Back
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                onConfirm()
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ConfirmationModal
