import { Text, Tooltip } from '@chakra-ui/react'

export const TruncatedText = ({ text }: { text: string }) => {
  if (text.length <= 50) return <Text>{text}</Text>

  return (
    <Tooltip label={text} placement="top">
      <Text>{text.slice(0, 50)}...</Text>
    </Tooltip>
  )
}
