import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ApiClient from '../../../utils/api/ApiClient'
import { transformPrescriberForUI } from '../../../pages/PrescriberProfilePage/utils'
import { setPrescriber } from '../../../pages/NewScriptPage/ScriptForm/PrescriberSlice'
import { enableIntercomService } from '../../../services/intercom/intercomService'

export interface AuthenticationRequest {
  code: string
  clientId: string
  redirectUri: string
}

export interface ReAuthenticationRequest {
  clientId: string
  refreshToken: string
}

export interface AuthenticationResponse {
  assessToken: string
  refreshToken: string
  idToken: string
  expiresIn: number
  email: string
  username: string
  firstName: string
  familyName: string
}

export type Permission = 'prescribing' | 'organization.manage'

type AuthenticationClientMethods = keyof typeof AuthenticationClient
export interface AuthenticationState {
  email: string
  firstName: string
  familyName: string
  username: string
  permissions: Permission[] | null
  hasError: boolean
  loading: {
    [K in AuthenticationClientMethods]?: string
  }
}

export interface ReAuthenticationResponse {
  assessToken: string
  idToken: string
  expiresIn: number
}

export const AuthenticationClient = {
  Authenticate: createAsyncThunk('auth', async (request: AuthenticationRequest) => {
    try {
      const { data: responseData } = await ApiClient.post(`/auth`, {
        code: request.code,
        clientId: request.clientId,
        redirectUri: request.redirectUri,
      })
      return { data: responseData as AuthenticationResponse }
    } catch (error) {
      return { error: new Error(`${error}`) }
    }
  }),
  Login: createAsyncThunk('auth/login', async (_, thunkAPI) => {
    const response = await ApiClient.post(`/auth/login`)
    const prescriber = response.data?.profiles?.prescribers?.[0]

    const admin = response.data?.profiles?.admins?.[0]
    const permissions = [
      admin ? 'organization.manage' : null,
      prescriber ? 'prescribing' : null,
    ].filter((value) => value) as Permission[]
    if (!prescriber) {
      localStorage.removeItem('prescriber')
      enableIntercomService()
      return { permissions }
    }

    enableIntercomService({
      user: {
        name: `${prescriber.givenNames} ${prescriber.familyName}`,
        email: prescriber.email,
      },
    })
    thunkAPI.dispatch(setPrescriber(transformPrescriberForUI(prescriber)))
    return { permissions }
  }),
  Logout: createAsyncThunk('auth/logout', async () => {
    const refreshToken = localStorage.getItem('refresh_token')
    if (refreshToken) {
      await ApiClient.post('/auth/token/revoke', { token: refreshToken })
      localStorage.clear()
    }
  }),
  refreshAccessToken: async (request: ReAuthenticationRequest) => {
    try {
      const { data: responseData } = await ApiClient.post(`/auth/refresh`, {
        clientId: request.clientId,
        refreshToken: request.refreshToken,
      })

      return { data: responseData as ReAuthenticationResponse }
    } catch (error) {
      return { error: new Error(`${error}`) }
    }
  },
}

const permissionsJSON = localStorage.getItem('permissions')
const currentUserEmail = localStorage.getItem('email') ?? ''
const userCognitoUsername = localStorage.getItem('username') ?? ''
const permissions: Permission[] = (permissionsJSON?.length && JSON.parse(permissionsJSON)) || null

const authenticationSlice = createSlice<AuthenticationState, Record<string, any>>({
  name: 'authentication',
  initialState: {
    email: currentUserEmail,
    firstName: '',
    familyName: '',
    username: userCognitoUsername,
    permissions,
    loading: {},
    hasError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AuthenticationClient.Authenticate.fulfilled, (state, action) => {
        if (action.payload?.data) {
          state.username = action.payload.data?.username || ''
          state.email = action.payload.data?.email || ''
          state.firstName = action.payload.data?.firstName || ''
          state.familyName = action.payload.data?.familyName || ''
          localStorage.setItem('email', state.email)
          localStorage.setItem('username', state.username)
        }
      })
      .addCase(AuthenticationClient.Login.pending, (state, { meta: { requestId } }) => {
        if (!state.loading.Login) {
          state.loading.Login = requestId
        }
      })
      .addCase(AuthenticationClient.Login.fulfilled, (state, { payload }) => {
        state.permissions = payload?.permissions ?? null
        state.loading.Login = undefined
        state.hasError = false
        localStorage.setItem('permissions', JSON.stringify(state.permissions))
      })
      .addCase(AuthenticationClient.Login.rejected, (state) => {
        state.loading.Login = undefined
        state.hasError = true
        state.permissions = null
        localStorage.setItem('permissions', JSON.stringify(state.permissions))
      })
      .addCase(AuthenticationClient.Logout.fulfilled, (state) => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('prescriber')
        localStorage.removeItem('permissions')
        localStorage.removeItem('email')
        localStorage.removeItem('username')
        localStorage.removeItem('client_id')
        localStorage.removeItem('landingPageParams')
        state.username = ''
        state.email = ''
        state.firstName = ''
        state.familyName = ''
        state.loading = {}
        state.permissions = []
      })
  },
})

export default authenticationSlice.reducer
