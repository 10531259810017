import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../pages/routes'
import { Fragment, useEffect } from 'react'
import { ssoClientMapping } from './constant'

export const SSOLandingPage = () => {
  const location = useLocation()
  const { identityProviderName = '' } = useParams()
  const providerName = identityProviderName?.toUpperCase()
  const navigate = useNavigate()
  const client = ssoClientMapping.find((client) => client.providerName === providerName)
  const clientId = client?.clientId || ''
  if (!providerName || !clientId) {
    navigate(routes.login.path)
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    const paramsObject: { [key in string]: string } = {}
    queryParams.forEach((value, key) => {
      paramsObject[key.toLowerCase()] = value
    })

    const paramsString = JSON.stringify(paramsObject)

    localStorage.setItem('landingPageParams', paramsString)
    const baseUrl = `${process.env.REACT_APP_COGNITO_AUTH_URI}/authorize`
    const redirectUri = `${process.env.REACT_APP_COGNITO_REDIRECT_URI}/${clientId}`
    const params = new URLSearchParams({
      response_type: 'code',
      identity_provider: providerName,
      client_id: clientId,
    })
    window.location.href = `${baseUrl}?${params.toString()}${'&redirect_uri=' + redirectUri}`
  }, [location.search, identityProviderName, navigate])
  return <Fragment />
}
