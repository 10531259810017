enum SSO_PROVIDER {
  TELECARE = 'TELECARE',
  HDA = 'HDA',
}

export const ssoClientMapping: {
  providerName: SSO_PROVIDER
  clientId?: string
  orgId?: string
  clinicId?: string
}[] = [
  {
    providerName: SSO_PROVIDER.TELECARE,
    clientId: process.env.REACT_APP_TELECARE_COGNITO_CLIENT_ID,
    orgId: process.env.REACT_APP_TELECARE_ORG_ID,
    clinicId: process.env.REACT_APP_TELECARE_CLINIC_ID,
  },
  {
    providerName: SSO_PROVIDER.HDA,
    clientId: process.env.REACT_APP_HDA_COGNITO_CLIENT_ID,
    orgId: process.env.REACT_APP_HDA_ORG_ID,
    clinicId: process.env.REACT_APP_HDA_CLINIC_ID,
  },
]
