import { Button, Divider, HStack, Text, VStack } from '@chakra-ui/react'
import parse from 'html-react-parser'
import React, { FunctionComponent, useCallback, useState } from 'react'
import Card from '../../common/components/Card'
import { ScrollableContainer } from '../../components/shared-components/ScrollableContainer'
import useApi from '../../common/utils/api/useApi'
import {
  PbsConcise,
  PbsRestriction,
  ProductDetails,
  ProductSearchClient,
} from './ScriptForm/ProductSearchContextSlice'
import { convertRestrictionType, describePbsProgram } from './utilities'
import { useAppSelector } from '../../app/typedReduxHooks'

interface AuthorityDetailsProps {
  details?: ProductDetails
  handleSelect?: (pbsData: PbsConcise, restriction?: PbsRestriction) => any
}

const AuthorityDetailsPanel: FunctionComponent<AuthorityDetailsProps> = ({
  details,
  handleSelect,
}) => {
  const organizationSettings = useAppSelector((state) => state.organization?.data?.settings)
  const isAbleToSelectS8Medicine = organizationSettings?.enableS8Medicine
  const isS8Medicine = !!details?.poisonClassification?.poisonClasses?.includes('S8')

  return (
    <Card p="2" variant="light" overflow="auto">
      <VStack spacing="4" alignItems="stretch">
        {details &&
          details.pbs.map((pbsData: PbsConcise) => (
            <PbsCard
              key={pbsData.pbsCode}
              pbsData={pbsData}
              handleSelect={handleSelect}
              disableSelect={!isAbleToSelectS8Medicine && isS8Medicine}
            />
          ))}
      </VStack>
    </Card>
  )
}

const PbsCard = ({
  pbsData,
  handleSelect,
  disableSelect,
}: {
  pbsData: PbsConcise
  handleSelect?: (pbsData: PbsConcise, restriction?: PbsRestriction) => any
  disableSelect: boolean
}) => {
  const { trigger: getFullPbsData, response: fullPbsData } = useApi(
    ProductSearchClient.getFullPbsData
  )

  const handleReadMore = (pbsCode: string) => {
    getFullPbsData({ pbsCode })
  }

  const resolvedData = fullPbsData ?? pbsData

  return (
    <Card padding={3}>
      <VStack alignItems="stretch" key={`${resolvedData.pbsCode}_${resolvedData.pbsType}`}>
        <HStack>
          <Text fontWeight="bold">PBS Code:</Text>
          <Text flexGrow="1">{resolvedData.pbsCode}</Text>
          {handleSelect && !resolvedData.restrictions.find((r) => r.streamlinedCode) && (
            <Button
              colorScheme="blue"
              size="sm"
              disabled={disableSelect}
              onClick={() => handleSelect(pbsData)}
            >
              Select
            </Button>
          )}
        </HStack>
        <HStack>
          <Text fontWeight="bold">PBS Type:</Text>
          <Text>{resolvedData.pbsType}</Text>
        </HStack>
        {resolvedData.pbsProgram ? (
          <HStack>
            <Text fontWeight="bold">PBS Program:</Text>
            <Text>{describePbsProgram(resolvedData.pbsProgram)}</Text>
          </HStack>
        ) : null}
        <HStack>
          <Text fontWeight="bold">Max Quantity:</Text>
          <Text>{resolvedData.maxQtyUnits}</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Max Repeats:</Text>
          <Text>{resolvedData.repeats}</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">PBS Manufacturer Code:</Text>
          <Text>{resolvedData.pbsManufacturerCodes?.join()}</Text>
        </HStack>
        {resolvedData.restrictions.map((restriction: PbsRestriction, idx: number) => (
          <RestrictionRenderer
            key={idx}
            pbsData={resolvedData}
            restriction={restriction}
            handleSelect={handleSelect}
            handleReadMore={handleReadMore}
            disableSelect={disableSelect}
          />
        ))}
      </VStack>
    </Card>
  )
}

interface RestrictionRendererProps {
  pbsData: PbsConcise
  restriction: PbsRestriction
  handleSelect?: (pbsData: PbsConcise, restriction?: PbsRestriction) => any
  handleReadMore: (pbsCode: string) => any
  disableSelect: boolean
}

const RestrictionRenderer: FunctionComponent<RestrictionRendererProps> = ({
  restriction,
  pbsData,
  handleSelect,
  handleReadMore,
  disableSelect,
}) => {
  const [isReadMoreClicked, setIsReadMoreClicked] = useState(false)
  const hasRestrictionText = (restriction.restrictionPreview?.split(' ').length || 0) >= 15
  const isShowReadMoreButton =
    (!isReadMoreClicked && hasRestrictionText) ||
    (isReadMoreClicked && !restriction.restrictionText)
  const description =
    (!isShowReadMoreButton ? restriction.restrictionText : restriction.restrictionPreview) ?? ''
  const handleClickReadMore = useCallback(() => {
    handleReadMore(pbsData.pbsCode)
    setIsReadMoreClicked(true)
  }, [])

  if (restriction.streamlinedCode) {
    return (
      <Card padding={3}>
        <VStack alignItems="stretch">
          <HStack>
            <Text fontWeight="bold">Streamline Code:</Text>
            <Text flex="1 1">{restriction.streamlinedCode}</Text>
            {handleSelect && (
              <Button
                colorScheme="red"
                size="sm"
                disabled={disableSelect}
                onClick={() => {
                  handleSelect(pbsData, restriction)
                }}
              >
                Select
              </Button>
            )}
          </HStack>
          <HStack>
            <Text fontWeight="bold">Restriction Type:</Text>
            <Text flex="1 1">{convertRestrictionType(restriction.restrictionType)}</Text>
          </HStack>
          <Divider />
          <ScrollableContainer maxHeight="400px" height="auto">
            <Text>{parse(description)}</Text>
          </ScrollableContainer>
          {isShowReadMoreButton && (
            <Button variant="link" onClick={handleClickReadMore}>
              Read more...
            </Button>
          )}
        </VStack>
      </Card>
    )
  } else if (description) {
    return (
      <>
        <Card padding="12px">
          <HStack>
            <Text fontWeight="bold">Restriction Type:</Text>
            <Text flex="1 1">{convertRestrictionType(restriction.restrictionType)}</Text>
          </HStack>
          <Divider />
          <ScrollableContainer maxHeight="400px" height="auto">
            <Text>{parse(description)}</Text>
          </ScrollableContainer>
          {isShowReadMoreButton && (
            <Button variant="link" onClick={handleClickReadMore}>
              Read more...
            </Button>
          )}
        </Card>
      </>
    )
  }
  return <></>
}

export default AuthorityDetailsPanel
