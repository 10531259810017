import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { FunctionComponent, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/typedReduxHooks'
import { blankPatient, selectPatient } from '../../features/patient/PatientContextSlice'
import { resetScript } from './ScriptForm/PrescribedItem/PrescribedItemSlice'

interface ResetScriptModalProps {
  children: FunctionComponent<{
    openModal: () => void
  }>
}

const ResetScriptModal: FunctionComponent<ResetScriptModalProps> = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const organizationSettings = useAppSelector((state) => state.organization?.data?.settings)
  const isAbleToManagePatient = organizationSettings?.enableManagePatient
  const dispatch = useAppDispatch()

  return (
    <>
      <props.children openModal={() => setModalIsOpen(true)}></props.children>

      <Modal
        isOpen={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false)
        }}
      >
        <ModalOverlay />
        <ModalContent width="100%" maxWidth="600px">
          <ModalHeader>Reset Prescription</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to clear this prescription?</Text>
          </ModalBody>

          <ModalFooter justifyContent="space-between">
            <Button mr={3} onClick={() => setModalIsOpen(false)}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                dispatch(resetScript())
                isAbleToManagePatient && dispatch(selectPatient(blankPatient))
                setModalIsOpen(false)
              }}
            >
              Clear
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ResetScriptModal
